<style lang="scss" scoped>
.page-order-line {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen, .order-mousetools {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .order-mousetools .btn:hover {
        background-color: #f0f0f0;
    }
    .order-mousetools {
        top: 42px;
        right: 5px;
        padding: 0;
        .btn {
            display: block;
            float: right;
            padding: 4px;
            i {
                font-size: 22px;
            }
        }
        .btn-open, .btn-open:hover {
            background: rgba(121, 184, 255, 1);
            i {
                color: #ffffff;
            }
        }
        .btn-actived {
            i {
                color: #79b8ff;
            }
        }
    }
}
</style>

<template>
    <div class="page-order-line">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <!--<div class="fl">
                    <el-button-group>
                        <el-button type="primary" :plain="listQuery.timeType !== 0" size="medium" @click="changeTimeType(0)">用车</el-button>
                        <el-button type="warning" :plain="listQuery.timeType === 0" size="medium" @click="changeTimeType(1)">还车</el-button>
                    </el-button-group>
                </div>-->
                <div class="fl">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" content="注：拖动时间可以快捷查看该时间段的数据。" placement="top">
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-question f16"></i></el-tag>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <span class="total-count col_primary" title="当前查询出的总车辆数">总数量：{{ orderJson.length }}</span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <span class="order-mousetools">
                <el-tooltip :content="isChooseOpen ? '结束框选' : '开始框选'" placement="top-end">
                    <span class="btn" :class="isChooseOpen ? 'btn-open' : ''"
                        @click="chooseSwitch()"><i :class="isChooseOpen ? 'el-icon-crop' : 'el-icon-crop'"></i></span>
                </el-tooltip>
                <template v-if="isChooseOpen">
                    <el-tooltip content="矩形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[0] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[0])"><i class="el-icon-menu"></i></span>
                    </el-tooltip>
                    <el-tooltip content="圆形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[1] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[1])"><i class="el-icon-bangzhu"></i></span>
                    </el-tooltip>
                </template>
            </span>
        </div>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    labelsLayer: null,
    parkingMarker: [],
    orderLines: [],
    mouseTool: null, // 鼠标绘制工具
    polygonObjs: [],
    textMarkers: []
}
export default {
    name: "pageOrderLine",
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "订单轨迹，以还车时间统计的订单数据。"
            },
            listQuery: {
                area: "",
                timeType: 1,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: "",
                keywords: ""
            },
            orderJson: [],
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(1, "d").format("YYYY-MM-DD"), moment().subtract(1, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            coverPaths: [], // 服务区多边形点位
            parkings: [],
            isShowParking: true,
            isChooseOpen: false, // 框选开关
            chooseTypeArray: [1, 2],
            chooseType: null,
            iconImages: ["/static/images/map/mass2.png", "/static/images/map/mass1.png", "/static/images/map/poi-marker-default.png"],
            lineColors: ["#f78989", "#67c23a", "#1791fc"],
            selectParkTypes: ["click", "overlay"],
            showLineParks: [],  // 能显示轨迹的站点队列
            isMapEditState: false
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.initLoadData()
        this.loadKeyDown()
        this.loadKeyUp()
    },
    unmounted() {
        dataVars.map?.destroy()
        document.removeEventListener("keydown")
        document.removeEventListener("keyup")
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        // 加载数据
        async initLoadData() {
            this.clearAllMarkers()
            await this.getPackings()
            await this._search()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            await funReport.GetParkingLocations({ area }).then(response => {
                this.parkings = response
            })
            this.setPackingData()
        },
        // 设置站点信息
        setPackingData() {
            this.clearParking()
            if (this.isShowParking) {
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.parkings))
                for (let i in datas) {
                    let item = datas[i]
                    let marker = new AMap.Polygon({
                        height: 10,
                        zIndex: 100,
                        bubble: true,
                        path: item.pointList,
                        strokeWeight: 1,
                        fillColor: "#1791fc", // "#f56c6c",
                        fillOpacity: 0.2,
                        strokeColor: "#1791fc", // "#f56c6c"
                        extData: item
                    })
                    // 站点点击选中
                    marker.on("click", (ev) => {
                        if (this.isMapEditState) {
                            return false
                        }
                        // this.clearMouseTool()
                        this.clearLineParks(this.selectParkTypes[0])
                        let packItem = ev.target.getExtData()
                        // 获取详情，骑入骑出数量
                        this.getParkingDetail(packItem)
                        // 显示隐藏订单轨迹线
                        this.setNeedShowOrderLineParks(this.selectParkTypes[0], packItem)
                        this.switchOrderLines()
                    })
                    dataVars.parkingMarker.push(marker)
                    dataVars.map.add(marker)
                }
            }
        },
        // 获取详情，骑入骑出数量
        getParkingDetail(packItem) {
            funReport.GetParkingById({ id: packItem.id }).then(res => {
                let rideInCount = 0
                let rideOutCount = 0
                dataVars.orderLines.forEach((ol, i) => {
                    let orderItem = ol.getExtData()
                    if (orderItem.startParkingId === packItem.id) {
                        rideOutCount++
                    } else if (orderItem.endParkingId === packItem.id) {
                        rideInCount++
                    }
                })
                let content = `<div>
                                    <div class="txtClose" style="position: absolute; z-index: 100; top: -6px; right: -6px;"><i class="el-icon-error f16 col6"></i></div>
                                    <div class="f13" style="padding: 5px 8px 5px 5px;">
                                        <div>站点名称：${res.name} <i class="el-icon-document-copy col_primary"></i></div>
                                        <div>骑入：${rideInCount}辆 <span class="col_success">(绿线)</span></div>
                                        <div>骑出：${rideOutCount}辆 <span class="col_danger">(红线)</span></div>
                                    </div>
                                </div>`
                this.mxCommonMapTextInfo(dataVars.map, {
                    draggable: true,
                    text: content,
                    position: [res.centerLng, res.centerLat]
                }, () => {
                    try {
                        window.navigator.clipboard.writeText(res.name).then(() => {
                            this.successMsg("站点名称复制成功!")
                        })
                    } catch(ex) {
                        this.alert(res.name, "手动复制站点", {
                            customClass: "wordbreakba"
                        })
                    }
                }, () => {
                    this.setNeedShowOrderLineParks(this.selectParkTypes[0], packItem, true)
                    this.switchOrderLines()
                })
            })
        },
        async getDataList() {
            // 清空数据
            // this.clearLineParks(this.selectParkTypes[0])
            // this.mxCloseCommonTextInfo(dataVars.map)
            this.orderJson = []
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetOrderData(this.listQuery).then(response => {
                this.orderJson = response.map(x => {
                    x.isShowLine = true
                    return x
                })
            })
            this.setPointData()
            this.setOrderLine()
            this.refreshTextsAndLines()
            window.$common.closeFullLoading()
        },
        // 关锁订单点位
        setPointData() {
            this.clearLabelsLayer()
            dataVars.labelsLayer = new AMap.LabelsLayer({
                collision: false
            })
            dataVars.map.add(dataVars.labelsLayer)
            let markers = []
            let datas = JSON.parse(JSON.stringify(this.orderJson))
            for (let i in datas) {
                let item = datas[i]
                let temp0 = {
                    position: [item.startLng, item.startLat],
                    icon: {
                        type: "image",
                        image: this.iconImages[0],
                        size: [5, 5],
                        anchor: 'center'
                    }
                }
                let labelMarker0 = new AMap.LabelMarker(temp0)
                markers.push(labelMarker0)
                let temp1 = {
                    position: [item.endLng, item.endLat],
                    icon: {
                        type: "image",
                        image: this.iconImages[1],
                        size: [5, 5],
                        anchor: 'center'
                    }
                }
                let labelMarker1 = new AMap.LabelMarker(temp1)
                markers.push(labelMarker1)
            }
            // dataVars.labelsLayer.add(markers)
        },
        // 订单轨迹线
        setOrderLine() {
            this.clearOrderLine()
            let datas = JSON.parse(JSON.stringify(this.orderJson))
            let tempOrders = []
            for (let i in datas) {
                let item = datas[i]
                // 如果订单开始站点和结束站点都相同，则只保留一条
                let tempIndex = tempOrders.findIndex(x => x.startParkingId === item.startParkingId && x.endParkingId === item.endParkingId)
                if (tempIndex !== -1) {
                    // continue
                }
                if (!item.startLng || !item.startLat || !item.endLng || !item.endLat) {
                    continue
                }
                // 没有开始和结束站点，就排除
                if (!item.startParkingId && !item.endParkingId) {
                    continue
                }
                // 判断是否在站点
                if (item.startParkingId) {
                    // 开始站点
                    let curPark = this.parkings.find(x => item.startParkingId === x.id)
                    if (curPark) {
                        item.startLng = curPark.centerLng
                        item.startLat = curPark.centerLat
                    } else {
                        continue
                    }
                }
                if (item.endParkingId) {
                    // 结束站点
                    let curPark = this.parkings.find(x => item.endParkingId === x.id)
                    if (curPark) {
                        item.endLng = curPark.centerLng
                        item.endLat = curPark.centerLat
                    } else {
                        continue
                    }
                }
                let arr = []
                let random = Math.random() * (1 - 0.5) + 0.5
                let baseLng = 0
                let baseLat = 0
                // 结束经度 > 开始经度，结束纬度 > 开始纬度
                if (item.endLng >= item.startLng && item.endLat > item.startLat) {
                    baseLng = -0.002
                    baseLat = 0.003
                } else if (item.endLng >= item.startLng && item.endLat < item.startLat) {
                    baseLng = 0.002
                    baseLat = 0.003
                } else if (item.endLng <= item.startLng && item.endLat < item.startLat) {
                    baseLng = 0.002
                    baseLat = -0.003
                } else if (item.endLng <= item.startLng && item.endLat > item.startLat) {
                    baseLng = -0.002
                    baseLat = -0.003
                }
                let ctLng = (item.startLng + item.endLng) / 2 + baseLng // * random
                let ctLat = (item.startLat + item.endLat) / 2 + baseLat // * random
                arr = [
                    [item.startLng, item.startLat],
                    [ctLng, ctLat, item.endLng, item.endLat]
                ]
                // 计算点位的数量，骑出多就红色，骑入多就绿色
                let strokeColor = ""
                let rideInCount = 0
                let rideOutCount = 0
                for (let t in datas) {
                    if (!datas[t].startParkingId && !datas[t].endParkingId) {
                        continue
                    }
                    if (item.startParkingId === datas[t].startParkingId) {
                        rideOutCount++
                    }
                    if (item.endParkingId === datas[t].endParkingId) {
                        rideInCount++
                    }
                }
                if (rideInCount > rideOutCount) {
                    // 骑入多
                    strokeColor = this.lineColors[1]
                } else {
                    // 骑出多
                    strokeColor = this.lineColors[0]
                }
                let parkLine = new AMap.BezierCurve({
                    bubble: true,
                    path: arr,            // 设置线覆盖物路径
                    strokeColor: strokeColor, // this.mxGetRandomColor(),   // 线颜色
                    strokeOpacity: 0.5,         // 线透明度
                    strokeWeight: 1.5,          // 线宽
                    strokeStyle: 'solid',     // 线样式
                    // showDir: true,
                    extData: item
                })
                tempOrders.push(item)
                dataVars.orderLines.push(parkLine)
                dataVars.map.add(parkLine)
            }
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverPaths = areaItem.pointList || []
            }
        },
        changeTimeType(val) {
            this.listQuery.timeType = val
            this._search()
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        _search() {
            this.getDataList()
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MouseTool"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // 创建鼠标绘制
            this.setMouseTools()
            // 右键
            this.setContextMenu()
        },
        setContextMenu() {
            // 创建右键
            let contextMenu = new AMap.ContextMenu()
            contextMenu.addItem("<i class='el-icon-star-on'></i> 轨迹初始化", (e) => {
                this.clearMouseTool()
                this.clearLineParks()
                this.mxCloseCommonTextInfo(dataVars.map)
                this.setOrderLine()
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-delete'></i> 清空框选", (e) => {
                this.clearMouseTool()
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-menu'></i> 矩形框选", (e) => {
                this.chooseType = this.chooseTypeArray[0]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-bangzhu'></i> 圆形框选", (e) => {
                this.chooseType = this.chooseTypeArray[1]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-lock'></i> 结束框选", (e) => {
                this.chooseSwitch(false)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-c-scale-to-original'></i> 开始/清空测距", (e) => {
                if (!dataVars.ruleTool) {
                    dataVars.ruleTool = new AMap.MouseTool(dataVars.map)
                    dataVars.ruleTool.rule()
                    this.isMapEditState = true
                } else {
                    dataVars.ruleTool.close(true)
                    dataVars.ruleTool = null
                    this.isMapEditState = false
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-full-screen'></i> 全屏/取消全屏", (e) => {
                this.isScreen = !this.isScreen
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-switch-button'></i> 关闭右键菜单", (e) => {
                contextMenu.close()
            })
            dataVars.map.on('rightclick', (e) => {
                contextMenu.open(dataVars.map, e.lnglat)
            })
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 计算服务区中心点
            let lngTotal = 0
            let latTotal = 0
            for (let i in path) {
                let arr = path[i]
                lngTotal += Number(arr[0])
                latTotal += Number(arr[1])
            }
            let curLnglat = [lngTotal / path.length, latTotal / path.length]
            this.moveToCenter({ lnglat: curLnglat })
        },
        // 框选地图显示对应的数据
        /************鼠标绘制选点************/
        // 创建鼠标绘制
        setMouseTools() {
            dataVars.mouseTool = new AMap.MouseTool(dataVars.map)
            dataVars.mouseTool.on("draw", async (event) => {
                // this.clearLineParks(this.selectParkTypes[0])
                // this.mxCloseCommonTextInfo(dataVars.map)
                // 面积必须大于0
                if (event.obj.getArea && event.obj.getArea() > 0) {
                    this.setPolygons(event.obj)
                    await this.setTextMarkers(event.obj)
                    this.switchPolygonOrderLines()
                    dataVars.map.remove(event.obj)
                }
            })
        },
        setPolygons(obj) {
            let polygon = null
            if (this.chooseType === this.chooseTypeArray[0]) {
                // 矩形
                polygon = new AMap.Rectangle(obj.getOptions())
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                // 圆形
                polygon = new AMap.Circle(obj.getOptions())
            }
            dataVars.polygonObjs.push(polygon)
            dataVars.map.add(polygon)
        },
        async setTextMarkers(obj) {
            let content = await this.getTextMarkerContent(obj)
            let curLnglat = obj.getCenter()
            let text = new AMap.Text({
                draggable: true,
                bubble: true,
                topWhenClick: true,
                text: content,
                anchor:"center",
                position: [curLnglat.lng, curLnglat.lat]
            })
            dataVars.textMarkers.push(text)
            dataVars.map.add(text)
            // 监听判断
            text.on("click", (event) => {
                let txt = event.target.dom.querySelector(".txtClose")
                let handleClick = (ev) => {
                    let isCloseClick = txt.contains(ev.target)
                    let index = dataVars.textMarkers.findIndex(x => x._amap_id === text._amap_id)
                    if (isCloseClick) {
                        this.switchPolygonOrderLines(index, true)
                        dataVars.map.remove(dataVars.polygonObjs[index])
                        dataVars.polygonObjs.splice(index, 1)
                        dataVars.map.remove(text)
                        dataVars.textMarkers.splice(index, 1)
                    } else {
                        this.switchPolygonOrderLines(index, false, true)
                    }
                    document.removeEventListener("click", handleClick)
                }
                document.addEventListener("click", handleClick)
            })
        },
        async getTextMarkerContent(obj, text) {
            let selectParks = this.parkings.filter(x => {
                return obj.contains([x.centerLng, x.centerLat])
            })
            let rideInCount = 0
            let rideOutCount = 0
            dataVars.orderLines.forEach((ol, i) => {
                let orderItem = ol.getExtData()
                let packStartItem = selectParks.find(x => orderItem.startParkingId === x.id)
                let packEndItem = selectParks.find(x => orderItem.endParkingId === x.id)
                if (packStartItem) {
                    rideOutCount++
                } else if (packEndItem) {
                    rideInCount++
                }
            })
            let content = `<div>
                                <div class="txtClose" style="position: absolute; z-index: 100; top: -6px; right: -6px;"><i class="el-icon-error f16 col6"></i></div>
                                <div class="f13" style="padding: 5px 8px 5px 5px;">
                                    <div>骑入：${rideInCount}辆 <span class="col_success">(绿线)</span></div>
                                    <div>骑出：${rideOutCount}辆 <span class="col_danger">(红线)</span></div>
                                </div>
                            </div>`
            // 如果是刷新数据，直接更新
            if (text) {
                text.setText(content)
            }
            return content
        },
        // 刷新时重新变更数据
        refreshTextsAndLines() {
            if (!this.showLineParks || this.showLineParks.length <= 0) {
                return false
            }
            // 有点击选中的站点，则更新数量
            let clickParkItem = this.showLineParks.find(x => x.type === this.selectParkTypes[0])
            if (clickParkItem) {
                this.getParkingDetail(clickParkItem)
            }
            // 更新框选的数据
            for(let i in dataVars.polygonObjs) {
                this.getTextMarkerContent(dataVars.polygonObjs[i], dataVars.textMarkers[i])
            }
            // 实时更新线路
            this.switchPolygonOrderLines()
        },
        // 获取需要显示订单轨迹的站点
        setNeedShowOrderLineParks(type, parkItem, isClose, isSwitch) {
            let index = this.showLineParks.findIndex(x => x.id === parkItem.id && type === x.type)
            let temp = JSON.parse(JSON.stringify(parkItem))
            temp.type = type || this.selectParkTypes[0] // 默认点击，overlay为框选
            // 如果是切换开关
            if (isSwitch) {
                if (index === -1) {
                    this.showLineParks.push(temp)
                } else {
                    this.showLineParks.splice(index, 1)
                }
                return false
            }
            // 关闭时删除对象
            if (isClose) {
                this.showLineParks.splice(index, 1)
                return false
            }
            // 不存在则新增
            if (index === -1) {
                this.showLineParks.push(temp)
            }
        },
        // 实时更新线路
        switchPolygonOrderLines(curIndex, isClose, isSwitch) {
            if (curIndex !== undefined) {
                let obj = dataVars.polygonObjs[curIndex]
                for (let x in this.parkings) {
                    let pItem = JSON.parse(JSON.stringify(this.parkings[x]))
                    if (obj.contains([pItem.centerLng, pItem.centerLat])) {
                        this.setNeedShowOrderLineParks(this.selectParkTypes[1], pItem, isClose, isSwitch)
                    }
                }
            } else {
                for (let i in dataVars.polygonObjs) {
                    let obj = dataVars.polygonObjs[i]
                    for (let x in this.parkings) {
                        let pItem = JSON.parse(JSON.stringify(this.parkings[x]))
                        if (obj.contains([pItem.centerLng, pItem.centerLat])) {
                            this.setNeedShowOrderLineParks(this.selectParkTypes[1], pItem)
                        }
                    }
                }
            }
            this.switchOrderLines()
        },
        // 共用显示隐藏订单轨迹线
        switchOrderLines() {
            // 更新订单轨迹
            dataVars.orderLines.forEach((ol, i) => {
                let item = ol.getExtData()
                let options = dataVars.orderLines[i].getOptions()
                // 站点点击选中更新
                let packStartItem = this.showLineParks.find(x => item.startParkingId === x.id)
                let packEndItem = this.showLineParks.find(x => item.endParkingId === x.id)
                if (packStartItem) {
                    // 骑出
                    this.setRideOptions(dataVars.orderLines[i], options, 1)
                } else if (packEndItem) {
                    // 骑入
                    this.setRideOptions(dataVars.orderLines[i], options, 0)
                } else {
                    dataVars.orderLines[i].hide()
                }
            })
        },
        setRideOptions(obj, options, type) {
            if (type) {
                // 骑出
                options.strokeColor = this.lineColors[0]
                options.strokeOpacity = 0.8
                obj.setOptions(options)
                obj.show()
            } else {
                // 骑入
                options.strokeColor = this.lineColors[1]
                options.strokeOpacity = 0.8
                obj.setOptions(options)
                obj.show()
            }
        },
        chooseSwitch(isOpen) {
            if (isOpen !== undefined) {
                this.isChooseOpen = isOpen
            } else {
                this.isChooseOpen = !this.isChooseOpen
            }
            if (!this.isChooseOpen) {
                dataVars.mouseTool && dataVars.mouseTool.close(true)
                this.isMapEditState = false
            } else {
                this.chooseSelectType(this.chooseType)
                this.isMapEditState = true
            }
        },
        chooseSelectType(type) {
            // 先移除之前的选框对象
            this.chooseType = type || this.chooseTypeArray[0]
            let options = {
                strokeColor: "#1791fc", //轮廓线颜色
                strokeOpacity: 0.3, //轮廓线透明度
                strokeWeight: 2, //轮廓线宽度
                fillColor: "#1791fc", //多边形填充颜色
                fillOpacity: 0.3, //多边形填充透明度
                strokeStyle: "solid", //线样式还支持 'dashed'
            }
            if (this.chooseType === this.chooseTypeArray[0]) {
                dataVars.mouseTool.rectangle(options)
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                dataVars.mouseTool.circle(options)
            }
        },
        loadKeyDown() {
            document.addEventListener("keydown", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(true)
                }
            })
        },
        loadKeyUp() {
            document.addEventListener("keyup", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(false)
                }
            })
        },
        clearAllMarkers() {
            this.clearLabelsLayer()
            this.clearParking()
            this.clearOrderLine()
            this.clearLineParks()
        },
        clearLabelsLayer() {
            dataVars.labelsLayer && dataVars.map.remove(dataVars.labelsLayer)
            dataVars.labelsLayer = null
        },
        clearParking() {
            for (let i in dataVars.parkingMarker) {
                dataVars.map.remove(dataVars.parkingMarker[i])
            }
            dataVars.parkingMarker = []
        },
        clearOrderLine() {
            for (let i in dataVars.orderLines) {
                dataVars.map.remove(dataVars.orderLines[i])
            }
            dataVars.orderLines = []
        },
        clearMouseTool() {
            this.clearPolygonMarkers()
            this.clearTextMarkers()
            dataVars.mouseTool && dataVars.mouseTool.close(true)
        },
        clearPolygonMarkers() {
            for (let i in dataVars.polygonObjs) {
                dataVars.map.remove(dataVars.polygonObjs[i])
            }
            dataVars.polygonObjs = []
        },
        clearTextMarkers() {
            for (let i in dataVars.textMarkers) {
                dataVars.map.remove(dataVars.textMarkers[i])
            }
            dataVars.textMarkers = []
        },
        clearLineParks(type) {
            if (!type) {
                this.showLineParks = []
            }
            for (let i in this.showLineParks) {
                if (type === this.showLineParks[i].type) {
                    this.showLineParks.splice(i, 1)
                }
            }
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat)
        }
    }
}
</script>